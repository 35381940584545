import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;

  h1 {
    padding: 32px;
  }
`;

export const Header = styled.button`
  display: flex;
  align-items: center;
  outline: 0;
  border: 0;
  background: none;

  svg {
    margin-right: 8px;
  }
`;

export const Content = styled.div`
  padding: 0 32px;

  div {
    margin-top: 32px;
  }

  strong {
    font-size: 24px;
  }

  p {
    padding: 8px 0;
  }

  li {
    margin-left: 64px;
    padding-bottom: 8px;
  }

  ul {
    padding-bottom: 16px;
  }

  a {
    margin: 0 4px;
  }

  span {
    margin-left: -4px;
  }
`;
