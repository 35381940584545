import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --color-background: #f9f7f7;
    --color-background-white: #fff;
    --color-background-dark: #e3dada;

    --color-primary: #db1622;
    --color-primary-light: #f6adb2;

    --color-secondary: #04D361;
    --color-secondary-dark: #04BF58;

    --color-text: #1b1c1e;
    --color-text-light: #878b93;
    --color-text-white: #f9f7f7;

    --color-control: #aaa;

    --color-error: #c53030;

    --color-unselected: #f4f4f4;

    --box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.2);

    --box-shadow-hover: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
    --box-shadow-hover-primary: 3px 3px 8px 0 rgba(219, 22, 34, 0.2);
    --box-shadow-small: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);
    --box-shadow-small-primary: 3px 3px 8px 0 rgba(219, 22, 34, 0.1);

    --color-background-input: #fcfbfb;

    --color-disabled: #eeeeee;

    --color-black-transparency: rgba(0, 0, 0, 0.8);
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea, span, strong {
    font-family: 'Poppins', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: bold;
  }

  button {
    cursor: pointer;
    outline: 0;
  }

  .button-facebook {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    min-height: 60px;
    margin-top: 32px;
    border: 0;
    border-radius: 32px 0 32px 32px;
    background: #4c69ba;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.4s;
    cursor: pointer;
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    color: #fff;

    &:hover {
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
    }

    svg {
      margin-right: 16px;
    }
  }

  ::-moz-selection {
    background: #fcd661;
    color: #000;
  }

  ::selection {
    background: #fcd661;
    color: #000;
  }
`;
