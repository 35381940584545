import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  max-width: 1120px;
  margin: 120px auto 0;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1010px) {
    width: 100%;
    padding: 32px;
    margin: 80px auto 0;
    flex-direction: column;
  }
`;

export const Title = styled.strong`
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  color: var(--color-primary);
`;

export const Subtitle = styled.strong`
  font-weight: bold;
  width: 100%;
  text-align: left;
  font-size: 36px;
  color: #000;
  margin-top: 8px;
`;

export const Content = styled.span`
  margin-top: 16px;
`;

export const DescriptionLeft = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  color: #343946;
  max-width: 400px;
  margin-right: 8px;

  @media (max-width: 1010px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

export const Button = styled.button`
  width: 200px;
  height: 40px;
  border: 0;
  background: var(--color-primary);
  color: var(--color-text-white);
  margin-top: 32px;
`;

export const DescriptionRight = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 16px;
  color: #343946;
  max-width: 450px;
  text-align: left;
  margin-left: 64px;
  margin-top: 16px;

  @media (max-width: 1010px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

export const Item = styled.span`
  display: flex;
  font-weight: 300;
  margin-top: 16px;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;

export const Icon = styled.div`
  margin-right: 16px;
  margin-top: 16px;
`;

export const Text = styled.span`
  font-weight: 300;

  strong {
    font-weight: bold;
  }
`;
