import styled, { css } from 'styled-components';

import background from '../../assets/background.svg';

interface IPageProps {
  selected?: boolean;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: overlay;
`;

export const Content = styled.div``;

export const Background = styled.div`
  background-image: url(${background});
  background-size: 100%;
  background-repeat: no-repeat;

  @media (max-width: 1700px) {
    background-size: 120%;
  }

  @media (max-width: 1400px) {
    background-size: 140%;
  }

  @media (max-width: 1100px) {
    background-size: 200%;
  }

  @media (max-width: 700px) {
    background-size: 280%;
  }

  @media (max-width: 500px) {
    background-size: 400%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 32px;
  background: #fff;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

  z-index: 2;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  > button {
    @media (max-width: 500px) {
      width: 130px;
      margin-left: 20px;
      font-size: 14px;
    }
  }
`;

export const Logo = styled.span`
  display: flex;
  align-items: center;

  width: 200px;
  height: 40px;

  font-family: 'Poppins';

  @media (max-width: 500px) {
    width: 150px;
  }

  img {
    height: 60px;
    width: 60px;
  }

  span {
    font-size: 24px;
    margin-left: 16px;

    @media (max-width: 500px) {
      display: none;
    }
  }
`;

export const Pages = styled.div`
  display: flex;
  margin-left: 256px;

  button + button {
    margin-left: 32px;
  }

  @media (max-width: 1140px) {
    margin-left: 128px;
  }

  @media (max-width: 990px) {
    margin-left: 0px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const PagesBottom = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;

  button + button {
    margin-left: 32px;
  }

  @media (max-width: 900px) {
    display: flex;
  }

  @media (max-width: 500px) {
    justify-content: space-between;
  }
`;

export const Page = styled.button<IPageProps>`
  color: ${({ selected }) => (selected ? 'var(--color-primary)' : '#000')};

  border: 0;
  background: none;

  padding-bottom: 8px;

  border-bottom: 4px solid transparent;
  transition: border-bottom 0.2s;

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 4px solid var(--color-primary);
        `
      : 'none'};

  &:hover {
    border-bottom: 4px solid var(--color-primary);
  }
`;

export const Button = styled.button`
  width: 200px;
  height: 40px;
  border: 0;
  background: var(--color-primary);
  color: var(--color-text-white);
`;

export const Main = styled.main`
  max-width: 1120px;
  margin: 0 auto 0;
  padding: 188px 32px 32px 32px;

  display: flex;

  @media (max-width: 800px) {
    margin: 0 auto 0;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 228px 32px 32px 32px;
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

export const ProductShowcase = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    margin-top: 32px;
  }
`;

export const ProductName = styled.strong`
  font-size: 64px;
  font-weight: bold;
`;

export const ProductDescription = styled.span`
  font-size: 24px;
  margin-top: 16px;
  color: #343946;
`;
