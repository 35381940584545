import styled from 'styled-components';

interface IFeatureName {
  first?: boolean;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1120px;
  width: 100%;
  margin: 120px auto 0;

  @media (max-width: 1180px) {
    padding: 0 32px;
  }
`;

export const Title = styled.strong`
  font-weight: bold;
  font-size: 24px;
  color: var(--color-primary);

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const Subtitle = styled.strong`
  font-weight: bold;
  font-size: 36px;
  color: #000;
  margin-top: 8px;

  @media (max-width: 550px) {
    text-align: left;
    width: 100%;
  }
`;

export const Description = styled.span`
  font-size: 16px;
  color: #343946;
  max-width: 800px;
  text-align: center;
  margin-right: 8px;
  margin-top: 16px;

  @media (max-width: 850px) {
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;

  @media (max-width: 1130px) {
    flex-direction: column;
    margin-top: 32px;
  }
`;

export const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescriptionLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  flex-direction: column;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  color: #343946;
`;

export const Features = styled.div``;

export const FeatureGroupContent = styled.div`
  display: flex;
`;

export const FeatureName = styled.div<IFeatureName>`
  height: 120px;
  font-weight: bold;
  width: 100%;
  padding: 12px;
  color: #71797e;
  border-top: ${({ first }) => (first ? '1px solid #71797e50' : '')};
  border-bottom: 1px solid #71797e50;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;

  @media (max-width: 1130px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const BenefitsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const Benefit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  svg {
    width: 64px;
    height: 64px;
    color: var(--color-primary);
  }
`;

export const BenefitContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`;

export const BenefitTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

export const BenefitDescription = styled.span``;

export const Button = styled.button`
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  border: 0;
  background: var(--color-primary);
  color: var(--color-text-white);
  margin-top: 32px;
`;
