export const unmaskCompanyDocument = (document: string): string =>
  document.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

export const unmaskPostalCode = (postalCode: string): string => {
  return postalCode.replace('.', '').replace('-', '');
};

export const unmaskPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace('-', '');
};
