import React, { useContext, useCallback, createContext } from 'react';

import IAcquirePlanDTO from '../model/IAcquirePlanDTO';
import ISendContactEmailDTO from '../model/ISendContactEmailDTO';
import ISendAcquirementEmailDTO from '../model/ISendAcquirementEmailDTO';

import api from '../services/api';
import { unmaskPhoneNumber, unmaskCompanyDocument } from '../utils/mask';

interface ICheckDomainResponse {
  subdomainExists: boolean;
}

interface ContactContextData {
  acquirePlan: (data: IAcquirePlanDTO) => Promise<void>;
  sendContactEmail: (data: ISendContactEmailDTO) => Promise<void>;
  checkSubdomain: (subdomain: string) => Promise<ICheckDomainResponse>;
  sendAcquirementEmail: (data: ISendAcquirementEmailDTO) => Promise<void>;
}

const ContactContext = createContext<ContactContextData>(
  {} as ContactContextData,
);

export const ContactProvider: React.FC = ({ children }) => {
  const sendContactEmail = useCallback(async (data: ISendContactEmailDTO) => {
    await api.post('/contacts', {
      plan: data.plan,
      email: data.email,
      name: `${data.name}`,
      message: data.message,
      tradingName: data.tradingName,
      phoneNumber: unmaskPhoneNumber(data.phone),
    });
  }, []);

  const sendAcquirementEmail = useCallback(
    async (data: ISendAcquirementEmailDTO) => {
      await api.post('/acquirements', {
        email: data.email,
        name: `${data.name} ${data.last_name}`,
        tradingName: data.company_name,
        document: unmaskCompanyDocument(data.company_document),
        phoneNumber: unmaskPhoneNumber(data.phone),
        plan: data.plan,
      });
    },
    [],
  );

  const acquirePlan = useCallback(async (data: IAcquirePlanDTO) => {
    await api.post('/accounts/company-acquisition', {
      ...data,
      deliveryFee: 0,
    });
  }, []);

  const checkSubdomain = useCallback(async (subdomain: string) => {
    const response = await api.get<ICheckDomainResponse>(
      `/companies/subdomain/${subdomain}`,
    );

    return response.data;
  }, []);

  return (
    <ContactContext.Provider
      value={{
        acquirePlan,
        checkSubdomain,
        sendContactEmail,
        sendAcquirementEmail,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export function useContact(): ContactContextData {
  const context = useContext(ContactContext);

  if (!context) {
    throw new Error('useContact must be usef within ContactProvider');
  }

  return context;
}
