import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IInputProps {
  hasError: boolean;
  hasFocus: boolean;
  hasContent: boolean;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #aaa;
  > a {
    text-decoration: none;
    color: #fcd661;
    font-size: 14px;
    transition: opacity 0.3s;
  }
  > a:hover {
    opacity: 0.6;
  }
`;

export const StyledTextarea = styled.textarea<IInputProps>`
  background: none;

  border: 0;
  ${({ hasError, hasContent, hasFocus }) => (hasError
    ? css`
          border-bottom: 1px solid #c53030;
        `
    : css`
          border-bottom: 1px solid
            ${hasContent || hasFocus ? '#fcd661' : '#aaa'};
        `)}
  padding: 16px 36px 16px 0;
  resize: none;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  top: 36px;
  right: 12px;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
