import React, { useCallback, useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import Footer from '../../components/Footer';
import AboutSection from '../../components/AboutSection';
import BenefitsSection from '../../components/BenefitsSection';
import TechnologySection from '../../components/TechnologySection';
import CapabilitiesSection from '../../components/CapabilitiesSection';

import logo from '../../assets/logo.png';
import showcase from '../../assets/bs-food-showcase.png';

import {
  Logo,
  Main,
  Page,
  Pages,
  Button,
  Header,
  Content,
  Container,
  Background,
  PagesBottom,
  ProductName,
  HeaderContent,
  ProductShowcase,
  ProductContainer,
  ProductDescription,
} from './styles';

interface IRouteProps {
  local: boolean;
}

const LandingPage: React.FC = () => {
  const techsRef = useRef<HTMLDivElement | null>(null);
  const productRef = useRef<HTMLDivElement | null>(null);
  const benefitsRef = useRef<HTMLDivElement | null>(null);

  const history = useHistory();

  const [visibleDiv, setVisibleDiv] = useState(() => {
    return history.location.pathname === '/benefits' ? 'benefits' : 'product';
  });

  useEffect(() => {
    if (
      history.location.state &&
      (history.location.state as IRouteProps).local
    ) {
      setTimeout(() => {
        if (history.location.pathname === '/benefits') {
          document
            .getElementById('benefits')
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      }, [10]);
    } else {
      setTimeout(() => {
        if (history.location.pathname === '/benefits') {
          document
            .getElementById('benefits')
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      }, [300]);
    }
  }, [history]);

  const handleOnScroll = useCallback(() => {
    if (productRef.current) {
      const { bottom } = productRef.current.getBoundingClientRect();

      if (bottom >= 0) {
        setVisibleDiv('product');
      }
    }

    if (techsRef.current) {
      const { bottom } = techsRef.current.getBoundingClientRect();

      if (bottom >= 0) {
        setVisibleDiv('product');
      }
    }

    if (benefitsRef.current) {
      const { top } = benefitsRef.current.getBoundingClientRect();

      if (top >= 0 && top <= window.innerHeight) {
        setVisibleDiv('benefits');
      }
    }
  }, []);

  useEffect(() => {
    document
      .getElementById('container')
      ?.addEventListener('scroll', handleOnScroll);
    return () =>
      document
        .getElementById('container')
        ?.removeEventListener('scroll', handleOnScroll);
  });

  const handleNavigateTo = useCallback(
    (route: string, div?: boolean, id?: string) => {
      if (div && id) {
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
        history.push(route, {
          local: true,
        });
        return;
      }

      history.push(route);
    },
    [history],
  );

  const handleNavigateToDashboard = useCallback(() => {
    window.open('https://portal.bsfood.com.br/');
  }, []);

  const handleOnPlanSelected = useCallback(
    (plan: number) => {
      history.push('/contact', { plan });
    },
    [history],
  );
  return (
    <Container id="container">
      <Background>
        <Content>
          <Header>
            <HeaderContent>
              <Logo>
                <img src={logo} alt="BS Food" />
              </Logo>
              <Pages>
                <Page
                  onClick={() => handleNavigateTo('/', true, 'product')}
                  type="button"
                  selected={visibleDiv === 'product'}
                >
                  Produto
                </Page>
                <Page
                  onClick={() =>
                    handleNavigateTo('/benefits', true, 'benefits')
                  }
                  type="button"
                  selected={visibleDiv === 'benefits'}
                >
                  Benefícios
                </Page>
                <Page
                  onClick={() => handleNavigateTo('/contact')}
                  type="button"
                >
                  Contato
                </Page>
                {/* <Page
                  onClick={() => handleNavigateTo('/acquire')}
                  type="button"
                >
                  Contrate
                </Page> */}
              </Pages>
              <Button onClick={handleNavigateToDashboard}>
                Acessar o portal
              </Button>
            </HeaderContent>
            <PagesBottom>
              <Page
                onClick={() => handleNavigateTo('/', true, 'product')}
                type="button"
                selected={visibleDiv === 'product'}
              >
                Produto
              </Page>
              <Page
                onClick={() => handleNavigateTo('/', true, 'benefits')}
                type="button"
                selected={visibleDiv === 'benefits'}
              >
                Benefícios
              </Page>
              {/* <Page onClick={() => handleNavigateTo('/acquire')} type="button">
                Contrate
              </Page> */}
            </PagesBottom>
          </Header>
          <Main id="product" ref={productRef}>
            <ProductContainer>
              <ProductName>BS FOOD</ProductName>
              <ProductDescription>
                Tenha seu cardápio totalmente digital e acelere suas vendas.
              </ProductDescription>
            </ProductContainer>
            <ProductShowcase>
              <img src={showcase} alt="BS Food" />
            </ProductShowcase>
          </Main>
        </Content>
        <AboutSection id="about" />
      </Background>
      <CapabilitiesSection id="capabilities" />
      <div ref={techsRef}>
        <TechnologySection id="techs" />
      </div>
      <div ref={benefitsRef}>
        <BenefitsSection id="benefits" onSelect={handleOnPlanSelected} />
      </div>
      <Footer />
    </Container>
  );
};

export default LandingPage;
