import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  TextareaHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Error, Label, Container, StyledTextarea } from './styles';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  title: string;
}

const TextArea: React.FC<ITextAreaProps> = ({ name, title, ...rest }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [hasFocus, setHasFocus] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleOnFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setHasFocus(false);
    setHasContent(!!textAreaRef.current?.value);
  }, []);

  return (
    <Container>
      <Label htmlFor={name}>{title}</Label>
      <StyledTextarea
        ref={textAreaRef}
        hasError={!!error}
        hasFocus={hasFocus}
        hasContent={hasContent}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default TextArea;
