/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { AiFillHeart } from 'react-icons/ai';

import {
  Top,
  Left,
  List,
  Email,
  Phone,
  Right,
  Title,
  Bottom,
  Button,
  Content,
  Message,
  Subtitle,
  Container,
  Copyright,
  Separator,
  LeftBottom,
  RightBottom,
  PrivacyPolicy,
  DoneBy,
} from './styles';

const Footer: React.FC = () => {
  const history = useHistory();

  const handleOnPhoneClick = useCallback(() => {
    window.open('https://wa.me/5519996707357', '_blank');
  }, []);

  const handleNavigateToContact = useCallback(() => {
    history.push('/contact');
  }, [history]);

  const handleNavigateToPrivacy = useCallback(() => {
    history.push('/privacy-policy');
  }, [history]);

  const handleNavigateTo = useCallback((id: string) => {
    document.getElementById(id)?.scrollIntoView();
  }, []);

  return (
    <Container>
      <Content>
        <Top>
          <Left>
            <Title>CONTATO</Title>
            <Subtitle>Como podemos te ajudar?</Subtitle>
            <Message>
              Entre em contato conosco através do email, ou telefone abaixo:
            </Message>
            <div>
              <PrivacyPolicy to="/privacy-policy">
                Política de Privacidade
              </PrivacyPolicy>
              <Email href="mailto:contato@bsfood.com.br">
                contato@bsfood.com.br
              </Email>
              <Phone onClick={handleOnPhoneClick}>(19) 9 9670-7357</Phone>
            </div>
          </Left>
          <Right>
            <Button onClick={handleNavigateToContact}>Vamos conversar</Button>
          </Right>
        </Top>
        <Separator />
        <Bottom>
          <LeftBottom>
            <Copyright>
              &copy; {`${new Date().getFullYear().toString()}, `}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://buildsolutions.com.br/"
              >
                Build Solutions
              </a>
              .
            </Copyright>
          </LeftBottom>
          <RightBottom>
            <List>
              <span>PRODUTO</span>
              <button type="button" onClick={() => handleNavigateTo('about')}>
                <span>Sobre</span>
              </button>
              <button
                type="button"
                onClick={() => handleNavigateTo('capabilities')}
              >
                <span>Funcionalidades</span>
              </button>
              <button type="button" onClick={() => handleNavigateTo('techs')}>
                <span>Abordagem</span>
              </button>
              <button
                type="button"
                onClick={() => handleNavigateTo('benefits')}
              >
                <span>Benefícios</span>
              </button>
            </List>
            <List>
              <span>EMPRESA</span>
              <Link to="/contact">Contato</Link>
              <a
                href="https://buildsolutions.com.br/empresa/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Sobre nós
              </a>
              <button type="button" onClick={handleNavigateToPrivacy}>
                <span>Privacidade</span>
              </button>
            </List>
          </RightBottom>
        </Bottom>
        <Separator />
        <DoneBy>
          <span>Feito com</span>
          <AiFillHeart size={20} />
          <span>por</span>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://buildsolutions.com.br/"
          >
            Build Solutions
          </a>
          <span>.</span>
        </DoneBy>
        <span className="info">
          João Reginaldo Pereira Queiroz Informatica | CNPJ 12.566.641/0001-97
        </span>
      </Content>
    </Container>
  );
};

export default Footer;
