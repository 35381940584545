import React, { useCallback } from 'react';

import { FaWhatsapp } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import { AiFillDashboard } from 'react-icons/ai';

import { Info, Title, Container, Description } from './styles';

interface ICapabilityProps {
  title: string;
  description: string;
  type: 'whatsapp' | 'dashboard' | 'speed';
}

const Capability: React.FC<ICapabilityProps> = ({
  type,
  title,
  description,
}) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case 'whatsapp':
        return <FaWhatsapp size={48} />;
      case 'dashboard':
        return <MdDashboard size={48} />;
      default:
        return <AiFillDashboard size={48} />;
    }
  }, [type]);

  return (
    <Container>
      {getIcon()}
      <Info>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Info>
    </Container>
  );
};

export default Capability;
