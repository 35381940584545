import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

// import AcquirePage from '../pages/Acquire';
import ContactPage from '../pages/Contact';
import LandingPage from '../pages/Landing';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" component={LandingPage} exact />
    <Route path="/benefits" component={LandingPage} />
    <Route path="/contact" component={ContactPage} />
    <Route path="/privacy-policy" component={PrivacyPolicyPage} />
    {/* <Route path="/acquire" component={AcquirePage} /> */}
    <Redirect to="/" />
  </Switch>
);

export default Routes;
