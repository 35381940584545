import React from 'react';

import { FaBookOpen, FaBox, FaChartPie, FaStar } from 'react-icons/fa';

import {
  Title,
  Content,
  Subtitle,
  Container,
  Description,
  BenefitDescription,
  Benefit,
  BenefitContentContainer,
  BenefitTitle,
  BenefitsContainer,
  BenefitsColumn,
  Button,
} from './styles';

interface IBenefitsProps {
  id: string;
  onSelect: (plan: number) => void;
}

const BenefitsSection: React.FC<IBenefitsProps> = ({ id, onSelect }) => (
  <Container id={id}>
    <Title>BENEFÍCIOS</Title>
    <Subtitle>Benefícios que só o BS Food tem.</Subtitle>
    <Description>
      Aproveite todas essas vantagens e garanta o desempenho que o seu
      estabelecimento merece.
    </Description>
    <Content>
      <BenefitsContainer>
        <BenefitsColumn>
          <Benefit>
            <FaChartPie />
            <BenefitContentContainer>
              <BenefitTitle>Gestão</BenefitTitle>
              <BenefitDescription>
                Gestor de pedidos on-line;
                <br />
                Painel para acompanhamento dos pedidos;
                <br />
                Api para integração com programas de terceiros.
              </BenefitDescription>
            </BenefitContentContainer>
          </Benefit>
          <Benefit>
            <FaBookOpen />
            <BenefitContentContainer>
              <BenefitTitle>Cardápio</BenefitTitle>
              <BenefitDescription>
                Cardápio on-line e na mesa;
                <br />
                Itens ilimitados.
              </BenefitDescription>
            </BenefitContentContainer>
          </Benefit>
        </BenefitsColumn>

        <BenefitsColumn>
          <Benefit>
            <FaBox />
            <BenefitContentContainer>
              <BenefitTitle>Pedidos</BenefitTitle>
              <BenefitDescription>
                Pedidos ilimitados (via WhatsApp);
                <br />
                Pedidos on-line integrado ao WhatsApp;
                <br />
                Até 500 pedidos por mês (via Gestor de Pedidos).
              </BenefitDescription>
            </BenefitContentContainer>
          </Benefit>
          <Benefit>
            <FaStar />
            <BenefitContentContainer>
              <BenefitTitle>Outros</BenefitTitle>
              <BenefitDescription>
                Subdomínio do seu estabelecimento;
                <br />
                Suporte via chamadas on-line.
              </BenefitDescription>
            </BenefitContentContainer>
          </Benefit>
        </BenefitsColumn>
      </BenefitsContainer>
    </Content>
    <Button onClick={() => onSelect(4)}>Solicite um orçamento</Button>
  </Container>
);

export default BenefitsSection;
