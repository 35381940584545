import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  padding: 32px;

  margin-top: 120px;

  background: #2e3233;
  justify-content: center;

  @media (min-width: 851px) {
    height: 660px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  max-width: 1120px;
  flex-direction: column;
  justify-content: space-between;

  span.info {
    color: #ccc;
    text-align: center;
    margin-top: 32px;
  }
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 850px) {
    margin-top: 32px;
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const Title = styled.strong`
  font-weight: bold;
  font-size: 18px;
  color: var(--color-primary);
  margin-top: 40px;

  @media (max-width: 850px) {
    margin-top: 0;
  }
`;

export const Subtitle = styled.strong`
  font-weight: bold;
  font-size: 32px;
  color: var(--color-background-white);
`;

export const Message = styled.span`
  font-weight: 300;
  font-size: 16px;
  color: #71797e;
  margin: 32px 0;
`;

export const Email = styled.a`
  font-weight: bold;
  font-size: 16px;
  color: var(--color-primary);

  cursor: pointer;

  text-decoration: none;

  padding-bottom: 8px;
  margin-bottom: 12px;

  border-bottom: 2px solid #2e3233;
  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 2px solid var(--color-primary);
  }
`;

export const PrivacyPolicy = styled(Link)`
  font-weight: bold;
  font-size: 16px;
  margin-right: 64px;

  color: var(--color-primary);

  cursor: pointer;

  text-decoration: underline;
`;

export const Phone = styled.strong`
  font-weight: bold;
  font-size: 16px;
  color: var(--color-primary);
  margin-left: 64px;

  cursor: pointer;

  padding-bottom: 8px;
  margin-bottom: 12px;

  border-bottom: 2px solid #2e3233;
  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 2px solid var(--color-primary);
  }

  @media (max-width: 850px) {
    margin-left: 0;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Right = styled.div``;

export const Button = styled.button`
  width: 200px;
  height: 40px;
  border: 0;
  margin-top: 64px;
  background: var(--color-primary);
  color: var(--color-text-white);

  @media (max-width: 850px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #71797e;
`;

export const CompanyName = styled.strong`
  color: var(--color-background-white);
  font-size: 24px;
  font-weight: bold;
`;

export const Copyright = styled.span`
  font-size: 14px;
  color: #71797e;

  a {
    color: #71797e;
    text-decoration: none;
    font-weight: bold;
  }
`;

export const LeftBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightBottom = styled.div`
  display: flex;

  margin-left: 164px;

  span {
    color: #71797e;
  }

  div + div {
    margin-left: 128px;

    @media (max-width: 850px) {
      margin-left: 64px;
    }
  }

  @media (max-width: 850px) {
    margin-left: 64px;
  }

  @media (max-width: 500px) {
    margin-left: 0;
    margin-bottom: 48px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 32px;

    @media (max-width: 850px) {
      margin-bottom: 16px;
    }
  }

  a {
    color: var(--color-background-white);
    text-decoration: none;
    border: 0;
    background: none;
    font-weight: 300;
    padding-bottom: 8px;
    margin-bottom: 12px;
    text-align: left;

    border-bottom: 2px solid #2e3233;

    transition: border-bottom 0.2s;

    &:hover {
      border-bottom: 2px solid var(--color-background-white);
    }
  }

  button {
    text-decoration: none;
    border: 0;
    background: none;
    font-weight: 300;
    text-align: left;
    margin-bottom: 24px;

    span {
      border-bottom: 2px solid #2e3233;
      padding-bottom: 8px;
      color: var(--color-background-white);
    }

    transition: border-bottom 0.2s;

    &:hover {
      span {
        border-bottom: 2px solid var(--color-background-white);
      }
    }
  }
`;

export const DoneBy = styled.div`
  margin: 0px auto;

  display: flex;
  align-items: center;
  color: var(--color-text-white);

  svg {
    margin: 0 6px;
    fill: var(--color-primary);
  }

  a {
    margin-left: 6px;
    color: var(--color-primary);
  }

  @media (max-width: 520px) {
    margin-top: 8px;
    justify-content: center;
  }
`;
