import styled from 'styled-components';

export const WhatsAppLink = styled.a`
  text-decoration: none;
  position: fixed;
  right: 0;
  bottom: 0;
  cursor: pointer;
  margin-bottom: 16px;
  margin-right: 32px;

  svg {
    width: 256px;
    filter: drop-shadow(0px 0px 4px #00000050);
  }

  @media (max-width: 768px) {
    margin-right: 16px;

    svg {
      width: 64px;
      height: 64px;
    }
  }
`;
