/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback } from 'react';

import { FiCheck } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Item,
  Icon,
  Text,
  Title,
  Button,
  Content,
  Subtitle,
  Container,
  DescriptionLeft,
  DescriptionRight,
} from './styles';

interface ITechnologyProps {
  id: string;
}

const TechnologySection: React.FC<ITechnologyProps> = ({ id }) => {
  const history = useHistory();

  const handleNavigateToContact = useCallback(() => {
    history.push('/contact');
  }, [history]);

  return (
    <Container id={id}>
      <DescriptionLeft>
        <Title>ABORDAGEM</Title>
        <Subtitle>Estratégias de tecnologias prontas para o futuro!</Subtitle>
        <Content>
          Adeque seu estabelecimento às melhores tecnologias do mercado.
        </Content>
        <Button onClick={handleNavigateToContact}>Entre em contato</Button>
      </DescriptionLeft>
      <DescriptionRight>
        <Item>
          <Icon>
            <FiCheck color="#fcd661" size={24} />
          </Icon>
          <Text>
            Site <strong>simples e direto</strong>, trazendo praticidade para
            seus
            <strong> clientes</strong>.
          </Text>
        </Item>
        <Item>
          <Icon>
            <FiCheck color="#fcd661" size={24} />
          </Icon>
          <Text>
            Com poucos cliques, você <strong>muda</strong> preço, imagens,
            descrição complementos ou categorias dos seus itens.
            <strong> É muito fácil</strong>.
          </Text>
        </Item>
        <Item>
          <Icon>
            <FiCheck color="#fcd661" size={24} />
          </Icon>
          <Text>
            No momento que um pedido for <strong>realizado</strong>, você é
            avisado através do nosso portal. Esse mesmo portal permite a
            <strong> impressão do pedido</strong>, controle de status para
            <strong> acompanhamento</strong> do cliente e mais.
          </Text>
        </Item>
      </DescriptionRight>
    </Container>
  );
};

export default TechnologySection;
