import styled, { css } from 'styled-components';

interface IContainerProps {
  bottom?: boolean;
  isShown: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: fixed;
  right: 0;
  z-index: 999;

  ${({ bottom }) =>
    bottom
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `}

  padding: ${({ isShown }) => (isShown ? '30px' : '0')};
  overflow: hidden;
`;
