import React, { useRef, useState, useCallback } from 'react';

import * as Yup from 'yup';
import VMasker from 'vanilla-masker';
import Lottie from 'lottie-react-web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import { useContact } from '../../hooks/contact';

import logo from '../../assets/logo.png';
import animation from '../../assets/loading.json';
import { getValidationErrors } from '../../utils/errors';
import acquisition_completed from '../../assets/acquisition-completed.json';

import Input from '../../components/Input';
import Footer from '../../components/Footer';
import Textarea from '../../components/Textarea';

import {
  Logo,
  Main,
  Page,
  Left,
  Email,
  Pages,
  Phone,
  Right,
  Title,
  Button,
  Header,
  Select,
  Message,
  Wrapper,
  Subtitle,
  Container,
  PagesBottom,
  SmallButton,
  ButtonRipple,
  SmallMessage,
  HeaderContent,
  HorizontalContainer,
  AcquisitionCompleted,
} from './styles';

interface IFormDataProps {
  name: string;
  email: string;
  message: string;
  tradingName: string;
}

interface IRouteParams {
  plan: 1 | 2 | 3 | 4;
}

const ContactPage: React.FC = () => {
  const formRef = useRef<FormHandles | null>(null);

  const history = useHistory();

  const { addToast } = useToast();
  const { sendContactEmail } = useContact();

  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [acquisitionCompleted, setAcquisitionCompleted] = useState(false);

  const [plan, setPlan] = useState<1 | 2 | 3 | 4>(() => {
    if (history.location.state) {
      return (history.location.state as IRouteParams).plan;
    }

    return 1;
  });

  const handleNavigateTo = useCallback(
    (route: string) => {
      history.push(route, {
        local: true,
      });
    },
    [history],
  );

  const handleOnPhoneClick = useCallback(() => {
    window.open('https://wa.me/5519996707357', '_blank');
  }, []);

  const handleNavigateToDashboard = useCallback(() => {
    window.open('https://portal.bsfood.com.br/');
  }, []);

  const handleSignIn = useCallback(
    async (data: IFormDataProps) => {
      if (loading) {
        return;
      }

      setLoading(true);

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string()
            .min(3, 'Mínimo de 3 caracteres.')
            .required('O nome é obrigatório.'),
          tradingName: Yup.string()
            .min(3, 'Mínimo de 3 caracteres.')
            .required('A empresa é obrigatória.'),
          email: Yup.string()
            .email('Digite um email válido.')
            .required('O email é obrigatório.'),
          message: Yup.string()
            .min(3, 'Mínimo de 3 caracteres.')
            .required('A mensagem é obrigatória.'),
          phone: Yup.string()
            .min(14, 'Mínimo de 14 caracteres.')
            .required('O telefone é obrigatório.'),
        });

        await schema.validate(
          { ...data, phone },
          {
            abortEarly: false,
          },
        );

        await sendContactEmail({
          ...data,
          plan,
          phone,
        });

        addToast({
          type: 'success',
          title: 'Email enviado com sucesso!',
        });

        setAcquisitionCompleted(true);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao enviar email!',
            description: 'Verifique sua conexão e tente novamente.',
          });
        }
      }
    },
    [plan, phone, loading, addToast, sendContactEmail],
  );

  const handleOnPhoneChanged = useCallback((text: string) => {
    setPhone(VMasker.toPattern(text, '(99) 9 9999-9999'));
  }, []);

  const handleOnPlanChanged = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      setPlan(Number(event.currentTarget.value) as 1 | 2 | 3 | 4);
    },
    [],
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Logo>
            <img src={logo} alt="BS Food" />
          </Logo>
          <Pages>
            <Page onClick={() => handleNavigateTo('/')} type="button">
              Produto
            </Page>
            <Page onClick={() => handleNavigateTo('/benefits')} type="button">
              Benefícios
            </Page>
            <Page
              onClick={() => handleNavigateTo('/contact')}
              type="button"
              selected
            >
              Contato
            </Page>
            {/* <Page onClick={() => handleNavigateTo('/acquire')} type="button">
              Contrate
            </Page> */}
          </Pages>
          <SmallButton onClick={handleNavigateToDashboard}>
            Acessar o portal
          </SmallButton>
        </HeaderContent>
        <PagesBottom>
          <Page onClick={() => handleNavigateTo('/')} type="button">
            Produto
          </Page>
          <Page
            onClick={() => handleNavigateTo('/contact')}
            type="button"
            selected
          >
            Contato
          </Page>
          {/* <Page onClick={() => handleNavigateTo('/acquire')} type="button">
            Contrate
          </Page> */}
        </PagesBottom>
      </Header>
      <Main>
        {acquisitionCompleted ? (
          <AcquisitionCompleted>
            <Lottie
              options={{
                animationData: acquisition_completed,
                loop: false,
              }}
              width={250}
              height={250}
            />
            <Wrapper>
              <span>Recebemos sua solicitação e, em breve, analisaremos.</span>
              <span>
                Ah, pode ser que entremos em contato por telefone, ou email,
                para a confirmação dos dados.
              </span>
            </Wrapper>
          </AcquisitionCompleted>
        ) : (
          <>
            <Left>
              <Title>CONTATO</Title>
              <Subtitle>Fale conosco.</Subtitle>
              <Message>
                Tem alguma dúvida, sugestão ou, mesmo, reclamação? Entre em
                contato, estamos ansiosos em ajudar!
              </Message>
              <SmallMessage>
                você pode, também, entrar em contato através do email, ou
                telefone abaixo:
              </SmallMessage>
              <div>
                <Email href="mailto:contato@bsfood.com.br">
                  contato@bsfood.com.br
                </Email>
                <Phone onClick={handleOnPhoneClick}>(19) 9 9670-7357</Phone>
              </div>
            </Left>
            <Right ref={formRef} onSubmit={handleSignIn}>
              <HorizontalContainer>
                <Input name="name" title="Nome" type="text" />
                <Input name="tradingName" title="Empresa" type="text" />
              </HorizontalContainer>
              <HorizontalContainer>
                <Input name="email" title="Email" type="text" />
                <Input
                  name="phone"
                  title="Telefone"
                  type="text"
                  value={phone}
                  onChange={e => handleOnPhoneChanged(e.target.value)}
                />
              </HorizontalContainer>
              <Textarea title="Mensagem" name="message" />
              <Select name="plan" value={plan} onChange={handleOnPlanChanged}>
                {/* <option value={1}>Plano LIGHT</option>
                <option value={2}>Plano BASIC</option>
                <option value={3}>Plano PRO</option> */}
                <option value={4}>Plano ENTERPRISE</option>
              </Select>
              <Button type="submit">
                <ButtonRipple color="#fff8">
                  {loading ? (
                    <Lottie
                      options={{
                        animationData: animation,
                      }}
                      height={60}
                      width={60}
                    />
                  ) : (
                    <div>
                      <span>Enviar</span>
                    </div>
                  )}
                </ButtonRipple>
              </Button>
            </Right>
          </>
        )}
      </Main>
      <Footer />
    </Container>
  );
};

export default ContactPage;
