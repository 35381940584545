/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import {
  Title,
  Subtitle,
  Container,
  DescriptionLeft,
  DescriptionRight,
  DescriptionContainer,
} from './styles';

interface IAboutProps {
  id: string;
}

const AboutSection: React.FC<IAboutProps> = ({ id }) => (
  <Container id={id}>
    <Title>SOBRE</Title>
    <Subtitle>Como podemos te ajudar?</Subtitle>
    <DescriptionContainer>
      <DescriptionLeft>
        O cardápio é exibido de forma <strong>digital</strong>, podendo ser
        acessado tanto da mesa, dentro do seu próprio estabelecimento, quanto{' '}
        <strong>da casa</strong> de seus clientes.
      </DescriptionLeft>
      <DescriptionRight>
        Basta uma leitura do <strong>QR Code</strong> que o usuário é
        redirecionado diretamente ao seu cardápio.
      </DescriptionRight>
    </DescriptionContainer>
  </Container>
);

export default AboutSection;
