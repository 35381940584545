import { Form } from '@unform/web';
import Ripple from 'react-ripples';
import styled, { css } from 'styled-components';

interface IPageProps {
  selected?: boolean;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: overlay;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 32px;
  background: var(--color-background-white);
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

  z-index: 2;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  > button {
    @media (max-width: 500px) {
      width: 130px;
      margin-left: 20px;
      font-size: 14px;
    }
  }
`;

export const Logo = styled.span`
  display: flex;
  align-items: center;

  width: 200px;
  height: 40px;

  font-family: 'Poppins';

  @media (max-width: 500px) {
    width: 150px;
  }

  img {
    height: 60px;
    width: 60px;
  }

  span {
    font-size: 24px;
    margin-left: 16px;

    @media (max-width: 500px) {
      display: none;
    }
  }
`;

export const Pages = styled.div`
  display: flex;
  margin-left: 256px;

  button + button {
    margin-left: 32px;
  }

  @media (max-width: 1140px) {
    margin-left: 128px;
  }

  @media (max-width: 1100px) {
    margin-left: 0px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const PagesBottom = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;

  button + button {
    margin-left: 32px;
  }

  @media (max-width: 900px) {
    display: flex;
  }

  @media (max-width: 500px) {
    justify-content: space-between;
  }
`;

export const Page = styled.button<IPageProps>`
  color: ${({ selected }) => (selected ? 'var(--color-primary)' : '#000')};

  border: 0;
  background: none;

  padding-bottom: 8px;

  border-bottom: 4px solid transparent;
  transition: border-bottom 0.2s;

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 4px solid var(--color-primary);
        `
      : 'none'};

  &:hover {
    border-bottom: 4px solid var(--color-primary);
  }
`;

export const ButtonRipple = styled(Ripple)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-height: 60px;
  border: 0;
  width: 100%;
  background: var(--color-primary);
  color: var(--color-text-white);

  > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }
`;

export const SmallButton = styled.button`
  width: 200px;
  height: 40px;
  border: 0;
  background: var(--color-primary);
  color: var(--color-text-white);
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-height: 60px;
  width: 100%;
  max-width: 250px;
  border: 0;
  background: var(--color-primary);
  transition: box-shadow 0.4s;
  cursor: pointer;

  margin-top: 32px;

  @media (max-width: 930px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Main = styled.main`
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: transparent;

  max-width: 1120px;
  margin: 0 auto 32px;
  padding: 200px 32px 32px 32px;

  @media (max-width: 930px) {
    width: 100%;
    margin: 0;

    flex-direction: column;

    background: none;
  }
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 50%;
  margin-right: 32px;

  @media (max-width: 930px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 64px;
    margin-right: 0;

    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Title = styled.strong`
  font-weight: bold;
  font-size: 24px;
  color: var(--color-primary);
`;

export const Subtitle = styled.strong`
  font-weight: bold;
  font-size: 36px;
  color: #000;
  margin-top: 8px;
`;

export const Message = styled.span`
  font-size: 18px;
  color: #343946;
  margin-top: 8px;
`;

export const SmallMessage = styled.div`
  font-size: 14px;
  color: #34394670;
  margin-top: 64px;
  margin-bottom: 32px;

  @media (max-width: 930px) {
    margin-top: 32px;
  }
`;

export const Email = styled.a`
  font-weight: bold;
  font-size: 16px;
  color: var(--color-primary);

  text-decoration: none;

  cursor: pointer;

  padding-bottom: 8px;
  margin-bottom: 12px;

  border-bottom: 2px solid transparent;
  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 2px solid var(--color-primary);
  }
`;

export const Phone = styled.strong`
  font-weight: bold;
  font-size: 16px;
  color: var(--color-primary);
  margin-left: 64px;

  cursor: pointer;

  padding-bottom: 8px;
  margin-bottom: 12px;

  border-bottom: 2px solid transparent;
  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 2px solid var(--color-primary);
  }

  @media (max-width: 930px) {
    margin-left: 0;
  }
`;

export const Right = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  flex: 1;

  > div {
    margin-bottom: 32px;
  }

  button {
    align-self: flex-end;
    height: 60px;
    width: 250px;

    @media (max-width: 930px) {
      width: 100%;
    }
  }

  @media (max-width: 930px) {
    margin-left: 0;
  }
`;

export const HorizontalContainer = styled.div`
  display: flex;

  > div + div {
    margin-left: 32px;
    width: 100%;

    @media (max-width: 930px) {
      margin-left: 0;
      margin-top: 32px;
    }
  }

  @media (max-width: 930px) {
    flex-direction: column;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;

  background: none;

  border: 0;
  padding: 0 8px;
  border-bottom: 1px solid var(--color-primary);

  font-family: 'Poppins';

  margin-top: 8px;
  margin-bottom: 32px;

  @media (max-width: 930px) {
    width: 100%;
  }
`;

export const AcquisitionCompleted = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 930px) {
    margin-top: 64px !important;
  }
`;

export const Wrapper = styled.div`
  margin-left: 64px;

  display: flex;
  flex-direction: column;

  span {
    font-size: 20px;
    font-weight: 500;
  }

  span + span {
    font-size: 14px;
    margin-top: 16px;
  }

  @media (max-width: 930px) {
    margin-left: 0;
    margin-top: 32px;
  }
`;
