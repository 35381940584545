import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 800px;
  box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.09);
  margin: 32px 0;
  padding: 32px;
  cursor: pointer;
  transition: transform 0.4s;

  &:hover {
    /* box-shadow: 8px 8px 32px 0 rgba(0, 0, 0, 0.15); */
    transform: translateX(-3%)
  }

  @media(min-width: 851px) {
    height: 150px;
  }

  @media(max-width: 850px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  @media(max-width: 850px) {
    margin-left: 0;
    margin-top: 8px;
  }
`;

export const Title = styled.strong`
  font-size: 20px;
  font-weight: bold;
`;

export const Description = styled.span``;
