import React from 'react';

import {
  Title,
  Subtitle,
  Container,
  Description,
  CapabilitiesContainer,
} from './styles';
import Capability from '../Capability';

interface ICapabilitiesProps {
  id: string;
}

const CapabilitiesSection: React.FC<ICapabilitiesProps> = ({ id }) => (
  <Container id={id}>
    <Title>FUNCIONALIDADES</Title>
    <Subtitle>Ferramentas para agilizar suas vendas.</Subtitle>
    <Description>
      Gerencie seus produtos, complementos, mesas, pedidos, tudo isso em um
      único portal. Gerenciar seu cardápio nunca foi tão rápido e prático.
    </Description>
    <CapabilitiesContainer>
      <Capability
        type="dashboard"
        title="Dashboard"
        description="Gerencie seus produtos, receba os pedidos e imprima-os em um só lugar."
      />
      <Capability
        type="whatsapp"
        title="WhatsApp"
        description="Além de receber os pedidos no dashboard, eles também vão direto para o seu WhatsApp."
      />
      <Capability
        type="speed"
        title="Velocidade"
        description="O cardápio é exibido de forma intuitiva e simples, facilitando o processo de compra."
      />
    </CapabilitiesContainer>
  </Container>
);

export default CapabilitiesSection;
