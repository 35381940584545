import React from 'react';

import { ReactComponent as WhatsAppButtonFull } from '../../assets/WhatsAppButtonFull.svg';
import { ReactComponent as WhatsAppButtonIcon } from '../../assets/WhatsAppButtonIcon.svg';

import { WhatsAppLink } from './styles';

const WhatsAppButton: React.FC = () => {
  const phoneNumber = '5519996707357';
  const textMessage =
    'Olá, tudo bem? Vim pelo site e tenho interesse no BSFood!';

  const formattedTextMessage = encodeURI(textMessage);

  const windowWidth = window.matchMedia('(max-width: 768px)');

  return (
    <WhatsAppLink
      aria-label="Converse no WhatsApp"
      href={`https://wa.me/${phoneNumber}?text=${formattedTextMessage}`}
      target="_blank"
    >
      {windowWidth.matches ? <WhatsAppButtonIcon /> : <WhatsAppButtonFull />}
    </WhatsAppLink>
  );
};

export default WhatsAppButton;
