import styled from 'styled-components';

export const Container = styled.section`
  max-width: 1120px;
  margin: 32px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    width: 100%;
    padding: 32px;
    margin: 0 auto 0;
  }
`;

export const Title = styled.strong`
  font-weight: bold;
  font-size: 24px;
  color: var(--color-primary);

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const Subtitle = styled.strong`
  font-weight: bold;
  font-size: 36px;
  color: #000;
  margin-top: 8px;

  @media (max-width: 850px) {
    text-align: left;
    width: 100%;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;

  @media (max-width: 850px) {
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
`;

export const DescriptionLeft = styled.span`
  font-size: 16px;
  color: #343946;
  max-width: 400px;
  text-align: right;
  margin-right: 8px;
  margin-top: 16px;

  @media (max-width: 850px) {
    text-align: left;
    max-width: 100%;
  }
`;

export const DescriptionRight = styled.span`
  font-size: 16px;
  color: #343946;
  max-width: 400px;
  text-align: left;
  margin-left: 8px;
  margin-top: 16px;

  @media (max-width: 850px) {
    margin-left: 0;
    max-width: 100%;
  }
`;
